import {
  ComponentType,
  forwardRef,
  ForwardRefRenderFunction,
  ReactChild,
  useImperativeHandle,
  useRef,
} from 'react'

import { CloseIcon } from '~/components/Abstracts/Icons'

import { usePopIn } from '~/providers/PopInProvider'

export interface PopInCloseIconProps {
  className?: string
  onClick?(): void
  as?: ComponentType<PopInCloseIconProps>
}

export const PopInCloseIcon = ({
  className,
  as,
  onClick,
}: PopInCloseIconProps) => {
  const { removeCurrent } = usePopIn()

  const processedOnClick = () => {
    removeCurrent()
    onClick?.()
  }

  const Component = as ?? CloseIcon

  return <Component className={className} onClick={processedOnClick} />
}

export interface PopInProps {
  className?: string
  children?: ReactChild[] | ReactChild
  isCloseButton?: boolean
  closeButton?: PopInCloseIconProps['as']
  closeClassName?: string
  onClose?(): void
}

const PopIn: ForwardRefRenderFunction<HTMLDivElement, PopInProps> = (
  {
    className,
    children,
    isCloseButton = true,
    closeButton,
    closeClassName,
    onClose,
  },
  ref,
) => {
  const popInRef = useRef(null)

  useImperativeHandle(ref, () => popInRef.current)

  return (
    <div ref={popInRef} className={className}>
      {isCloseButton && (
        <PopInCloseIcon
          className={closeClassName}
          as={closeButton}
          onClick={onClose}
        />
      )}
      {children}
    </div>
  )
}

export default forwardRef<HTMLDivElement, PopInProps>(PopIn)
