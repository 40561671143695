import { TRACKING_BRAND } from '~/lib/constants'
import {
  getTrackingFromProcessedCartItems,
  processCartItems,
} from '~/lib/process-cart-items'

import {
  PRODUCT_TYPE_TRACKING,
  SAMPLE_TYPE_TRACKING,
} from '~/components/Panels/CartPanel/CartContent/types'
import { ProductHeaderProps } from '~/components/UI/ProductHeader/types'

import useGetCart from '~/hooks/cart/useGetCart'
import { serializeTrackingProduct } from '~/hooks/tracking/useGTMTracking'
import {
  getDiscountPercentOfProduct,
  getDiscountValueOfProduct,
  getGTMTypeFromPrismicType,
  getNonDiscountedPriceProduct,
} from '~/hooks/tracking/utils'

import { getCurrentColor } from '~/utils/get-current-color'
import { getIsProductSet } from '~/utils/get-shopify-product-type'

export default function useCartItems({
  type,
  uid,
  name,
  image,
  selectedVariant,
  shopifyId,
  productType,
  sample,
  colorVariants,
  images,
  productIsGiftCard,
}: ProductHeaderProps) {
  const { cart } = useGetCart()

  // If product is a set, setting other attributes
  const setAttributes = getIsProductSet(type)
    ? {
        sets: 'true',
        setsData: JSON.stringify({
          products: selectedVariant?.components,
        }),
      }
    : {}

  const cartItems = processCartItems({
    merchandiseId: selectedVariant?.id ?? null,
    quantity: 1,
    attributes: {
      slug: uid ?? null,
      name: name ?? null,
      image: image?.src ?? null,
      shopifyId: shopifyId ?? null,
      productType: productType ?? null,
      prismicType: productIsGiftCard ? 'gift_card' : type,
      realProduct: 'true',
      productLinkedId: sample?.shopifyId,
      color: getCurrentColor(colorVariants)?.name ?? null,
      colorHex: getCurrentColor(colorVariants)?.color ?? null,
      trackingData: JSON.stringify(
        serializeTrackingProduct({
          item_id: selectedVariant?.sku ?? null,
          id: selectedVariant?.barcode?.toString() ?? null,
          name,
          price: selectedVariant?.priceAmount ?? null,
          brand: TRACKING_BRAND,
          // re-enable it later
          // category: productType ?? null,
          category: getGTMTypeFromPrismicType(type),
          type: getGTMTypeFromPrismicType(type),
          variant: selectedVariant?.title ?? null,
          color: getCurrentColor(colorVariants)?.name ?? null,
          quantity: '1',
          discountPercentage: getDiscountPercentOfProduct(selectedVariant),
          discount: getDiscountValueOfProduct(selectedVariant),
          catalogPrice: getNonDiscountedPriceProduct(selectedVariant),
          productType: getIsProductSet(type)
            ? PRODUCT_TYPE_TRACKING.BUNDLE
            : PRODUCT_TYPE_TRACKING.PRODUCT,
          mediaResources: images?.[0]?.alt ?? null,
        }),
      ),
      ...setAttributes,
    },
  })

  // const cartItems =
  //   type === PRISMIC_TYPES.PRODUCT_SETS
  //     ? [
  //         processCartItems({
  //           merchandiseId: setsVariants?.[0]?.variant?.id ?? null,
  //           quantity: 1,
  //           attributes: {
  //             slug: uid ?? null,
  //             setsName: name ?? null,
  //             setsImage: image?.src ?? null,
  //             name: productSetItems?.firstProduct?.name ?? null,
  //             shopifyId: setsVariants?.[0]?.shopifyId ?? null,
  //             prismicType: type,
  //             realProduct: 'true',
  //             sets: 'true',
  //             productLinkedId: setsVariants?.[1]?.shopifyId ?? null,
  //             customPrice: `${productCMSPrice}`,
  //             trackingData: JSON.stringify(
  //               serializeTrackingProduct({
  //                 id: setsVariants?.[0]?.variant?.barcode?.toString() ?? null,
  //                 name: productSetItems?.firstProduct?.name,
  //                 price: setsVariants?.[0]?.variant?.priceAmount ?? null,
  //                 brand: TRACKING_BRAND,
  //                 // re-enable it later
  //                 // category: productType ?? null,
  //                 category: getGTMTypeFromPrismicType(type),
  //                 type: getGTMTypeFromPrismicType(type),
  //                 variant: setsVariants?.[0]?.variant?.title ?? null,
  //                 quantity: '1',
  //                 discountPercentage: getDiscountPercentOfProduct(
  //                   setsVariants?.[0]?.variant,
  //                 ),
  //                 discount: getDiscountValueOfProduct(
  //                   setsVariants?.[0]?.variant,
  //                 ),
  //                 catalogPrice: getNonDiscountedPriceProduct(
  //                   setsVariants?.[0]?.variant,
  //                 ),
  //                 productType: PRODUCT_TYPE_TRACKING.SETS,
  //                 mediaResources: images?.[0]?.alt ?? null,
  //               }),
  //             ),
  //           },
  //         }),
  //         processCartItems({
  //           merchandiseId: setsVariants?.[1]?.variant?.id ?? null,
  //           quantity: 1,
  //           attributes: {
  //             slug: uid ?? null,
  //             setsName: name ?? null,
  //             setsImage: image?.src ?? null,
  //             name: productSetItems?.secondProduct?.name ?? null,
  //             shopifyId: setsVariants?.[1]?.shopifyId ?? null,
  //             prismicType: type,
  //             realProduct: 'true',
  //             sets: 'true',
  //             trackingData: JSON.stringify(
  //               serializeTrackingProduct({
  //                 id: setsVariants?.[1]?.variant?.barcode?.toString() ?? null,
  //                 name: productSetItems?.secondProduct?.name,
  //                 price: setsVariants?.[1]?.variant?.priceAmount ?? null,
  //                 brand: TRACKING_BRAND,
  //                 // re-enable it later
  //                 // category: productType ?? null,
  //                 category: getGTMTypeFromPrismicType(type),
  //                 type: getGTMTypeFromPrismicType(type),
  //                 variant: setsVariants?.[1]?.variant?.title ?? null,
  //                 quantity: '1',
  //                 discountPercentage: getDiscountPercentOfProduct(
  //                   setsVariants?.[1]?.variant,
  //                 ),
  //                 discount: getDiscountValueOfProduct(
  //                   setsVariants?.[1]?.variant,
  //                 ),
  //                 catalogPrice: getNonDiscountedPriceProduct(
  //                   setsVariants?.[1]?.variant,
  //                 ),
  //                 productType: PRODUCT_TYPE_TRACKING.SETS,
  //                 mediaResources: images?.[0]?.alt ?? null,
  //               }),
  //             ),
  //           },
  //         }),
  //       ]
  //         ?.flat(2)
  //         ?.filter(Boolean)
  //     : processCartItems({
  //         merchandiseId: selectedVariant?.id ?? null,
  //         quantity: 1,
  //         attributes: {
  //           slug: uid ?? null,
  //           name: name ?? null,
  //           image: image?.src ?? null,
  //           shopifyId: shopifyId ?? null,
  //           productType: productType ?? null,
  //           prismicType: productIsGiftCard ? 'gift_card' : type,
  //           realProduct: 'true',
  //           productLinkedId: sample?.shopifyId,
  //           color: getCurrentColor(colorVariants)?.name ?? null,
  //           colorHex: getCurrentColor(colorVariants)?.color ?? null,
  //           trackingData: JSON.stringify(
  //             serializeTrackingProduct({
  //               id: selectedVariant?.barcode?.toString() ?? null,
  //               name,
  //               price: selectedVariant?.priceAmount ?? null,
  //               brand: TRACKING_BRAND,
  //               // re-enable it later
  //               // category: productType ?? null,
  //               category: getGTMTypeFromPrismicType(type),
  //               type: getGTMTypeFromPrismicType(type),
  //               variant: selectedVariant?.title ?? null,
  //               color: getCurrentColor(colorVariants)?.name ?? null,
  //               quantity: '1',
  //               discountPercentage:
  //                 getDiscountPercentOfProduct(selectedVariant),
  //               discount: getDiscountValueOfProduct(selectedVariant),
  //               catalogPrice: getNonDiscountedPriceProduct(selectedVariant),
  //               productType: PRODUCT_TYPE_TRACKING.PRODUCT,
  //               mediaResources: images?.[0]?.alt ?? null,
  //             }),
  //           ),
  //         },
  //       })

  /* It's checking if the sample is already in the cart. */
  const asAlreadyTryMeSample = Boolean(
    cart?.products?.find(
      (product) => product?.variantId === sample?.variant?.id,
    ),
  )

  const sampleCartItems =
    sample && !asAlreadyTryMeSample
      ? processCartItems({
          merchandiseId: sample?.variant?.id ?? null,
          quantity: 1,
          attributes: {
            item_id: sample?.variant?.sku ?? null,
            name: sample?.name ?? null,
            image: sample?.image?.src ?? null,
            shopifyId: sample?.shopifyId ?? null,
            prismicType: type,
            sample: 'true',
            productType: sample?.productType,
            sampleFromProduct: 'true',
            trackingData: JSON.stringify(
              serializeTrackingProduct({
                item_id: sample?.variant?.sku ?? null,
                id: sample?.variant?.barcode?.toString() ?? null,
                name: sample?.name ?? null,
                price: sample?.variant?.priceAmount ?? null,
                brand: TRACKING_BRAND,
                // re-enable it later
                // category: sample?.productType ?? null,
                category: getGTMTypeFromPrismicType(type),
                type: getGTMTypeFromPrismicType(type),
                variant: sample?.variant?.title ?? null,
                quantity: '1',
                isSample: 'true',
                sampleType: SAMPLE_TYPE_TRACKING.TRY_ME,
                productType: PRODUCT_TYPE_TRACKING.SAMPLE,
              }),
            ),
          },
        })
      : []

  const processedProducts = [...cartItems, ...sampleCartItems]

  const productsTracking = getTrackingFromProcessedCartItems(cartItems)
  const sampleTracking = getTrackingFromProcessedCartItems(sampleCartItems)

  return [processedProducts, productsTracking, sampleTracking]
}
