import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import Cta, { CtaBaseProps } from '~/components/Abstracts/Cta'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface BigButtonProps extends CtaBaseProps {
  className?: string
  active?: boolean
  isOutOfStock?: boolean
}

function BigButton({
  className,
  active,
  isOutOfStock,
  ...rest
}: BigButtonProps) {
  const textStyle = useStyle({
    textPreset: GlobalTextPreset.Cta11Haffer,
    color: active ? GlobalThemeColors.White : GlobalThemeColors.Black,
    backgroundColor: active
      ? GlobalThemeColors.Black
      : GlobalThemeColors.Transparent,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const props = {
    css,
    cx,
  }

  return (
    <Cta
      className={cx(className, textStyle, css.BigButton, { isOutOfStock })}
      {...props}
      {...rest}
    />
  )
}

BigButton.defaultProps = {}

export default BigButton
