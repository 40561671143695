import { Variant } from '~/@types/product'

import VariantsFashion from '~/components/UI/VariantsFashion'
import VariantsFragrance from '~/components/UI/VariantsFragrance'

import {
  getIsProductFashion,
  getIsProductFragrance,
  getIsProductSet,
} from '~/utils/get-shopify-product-type'

export interface ProductVariantsProps {
  type: string
  className?: string
  active?: Variant
  variants?: Variant[]
}

function ProductVariants({
  className,
  type,
  variants,
  ...props
}: ProductVariantsProps) {
  return (
    <>
      {/* If Fashion type add the variants select */}
      {(getIsProductFashion(type) ||
        (getIsProductSet(type) && variants?.length > 1)) && (
        <VariantsFashion className={className} variants={variants} {...props} />
      )}

      {/* If Fragrance type add the variants squared buttons */}
      {getIsProductFragrance(type) && (
        <VariantsFragrance
          className={className}
          variants={variants}
          {...props}
        />
      )}
    </>
  )
}

export default ProductVariants
