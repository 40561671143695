import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { ComponentProps } from 'react'
import { useTranslate } from 'react-polyglot'
import { CardProduct } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { PropsWithClassName } from '~/@types/generic'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_EVENTS } from '~/lib/constants'

import ShopifyRichText from '~/components/Abstracts/ShopifyRichText'
import VideoComponentForwarded from '~/components/Abstracts/Video'
import { ProductCardProps } from '~/components/Slices/ProductCard'
import AddToCartButton from '~/components/UI/AddToCartButton'
import { ColorsProductProps } from '~/components/UI/ColorsProduct'
import ColorsProductHeader from '~/components/UI/ColorsProductHeader'
import Image from '~/components/UI/Image'
import InlineCta from '~/components/UI/InlineCta'
import PopIn from '~/components/UI/Popin'
import KlarnaLabel from '~/components/UI/ProductHeader/ProductDetails/KlarnaLabel'
import ProductVariants from '~/components/UI/ProductHeader/ProductVariants'

import { usePopIn } from '~/providers/PopInProvider'
import ShopifyProductProvider, {
  useShopifyProductContext,
} from '~/providers/ShopifyProductProvider'
import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import useCartItems from '~/hooks/cart/useCartItems'
import useCurrency from '~/hooks/useCurrency'
import { useGetProductCard } from '~/hooks/useGetProductCard'
import useVariant from '~/hooks/useVariant'

import { isGID } from '~/utils/shopify-ids'

import { PRODUCT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

const IMAGE_SIZES = [{ ratio: 1 }]

export type QuickbuyPopinProps = PropsWithClassName<
  ProductCardProps & {
    type: CardProduct['type']
    colorVariants?: ColorsProductProps['colors']
  }
>

function InnerQuickbuyPopin({
  className,
  type,
  colorVariants,
  ...rest
}: QuickbuyPopinProps) {
  const t = useTranslate()
  const { tracker } = useTracker()
  const currency = useCurrency()

  const { removeCurrent } = usePopIn()

  const {
    variants,
    defaultVariant,
    isLoading: isContextLoading,
    productData,
    setProductId,
  } = useShopifyProductContext()

  const { data, isLoading, isFetching } = useGetProductCard({
    sfProduct: productData,
    initialData: rest,
  })

  const isComponentLoading = isContextLoading || isLoading || isFetching

  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title18Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.Black,
  })

  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Text12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.DoveGray,
  })

  const { selectedVariant } = useVariant(variants, defaultVariant)

  const [cartItems, productsTracking, sampleTracking] = useCartItems({
    uid: selectedVariant?.uid,
    type,
    name: data?.name,
    image: data?.image?.image ?? selectedVariant?.image ?? null,
    selectedVariant,
    shopifyId: data?.shopifyId,
    productType: data?.productType,
  })

  const handleAddToCart = () => {
    const trackingPayloadAddToCart = {
      eventLabel: data?.name,
      ecommerce: {
        currencyCode: currency,
        add: {
          products: productsTracking,
        },
      },
    }

    tracker.emit(TRACKING_EVENTS.ADD_TO_CART, trackingPayloadAddToCart)

    sampleTracking?.forEach((sample) => {
      tracker.emit(TRACKING_EVENTS.ADD_SAMPLES, { eventLabel: sample?.name })
    })

    removeCurrent?.()
  }

  const image =
    data?.image?.image ?? rest?.image?.image ?? data?.imageHover?.image
  const video = data?.image?.video ?? false

  return (
    <AnimatePresence>
      <m.div
        className={css.AnimationContainer}
        initial={{ translateY: 15, opacity: 0 }}
        animate={{
          translateY: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: [0.345, 0.45, 0.39, 0.94],
          },
        }}
        exit={{
          opacity: 0,
          transition: {
            duration: 0.5,
          },
        }}>
        <PopIn
          className={cx(
            css.QuickbuyPopin,
            className,
            isComponentLoading && css.skeleton,
          )}
          closeClassName={css.closeButton}>
          {isComponentLoading ? (
            <Skeleton />
          ) : (
            <>
              <div className={css.assetWrapper}>
                {video && (
                  <VideoComponentForwarded
                    {...video}
                    autoPlay
                    muted
                    loop
                    playsInline
                    loading={video ? 'eager' : 'lazy'}
                  />
                )}
                {!video && image && (
                  <Image
                    draggable="false"
                    layout="fill"
                    objectFit="cover"
                    className={cx(css.image)}
                    ratio={css.ratio}
                    sizesFromBreakpoints={IMAGE_SIZES}
                    asPlaceholder
                    priority
                    {...image}
                  />
                )}
              </div>
              <div className={css.content}>
                <div className={css.productInfo}>
                  {data?.name && (
                    <h3 className={cx(css.title, nameStyle)}>{data?.name}</h3>
                  )}
                  <ShopifyRichText
                    className={cx(css.label, labelStyle)}
                    render={data?.label}
                  />
                  {/* If Fashion type dislay colors variants */}
                  {colorVariants?.length > 0 && (
                    <ColorsProductHeader
                      items={colorVariants?.map((item, index) => ({
                        ...item,
                        currentIndex: colorVariants?.findIndex(
                          (color) => color?.uid === data?.shopifyId,
                        ),
                        link: {
                          onClick: () => {
                            const currentProductColorId =
                              colorVariants?.[index]?.uid

                            if (isGID('Product', currentProductColorId)) {
                              setProductId(currentProductColorId)
                            }
                          },
                        },
                      }))}
                      className={css.colorsVariants}
                    />
                  )}
                </div>
                <div className={css.productsActions}>
                  <ProductVariants
                    className={cx(css.variants, css[type])}
                    type={type}
                    active={selectedVariant}
                    variants={variants}
                  />
                  <AddToCartButton
                    className={css.addToCartButton}
                    price={selectedVariant?.price}
                    disabled={!selectedVariant?.price}
                    cartItems={cartItems}
                    onAdded={handleAddToCart}
                  />
                  <KlarnaLabel type={type} className={css.klarnaWrapper} />
                </div>
                <InlineCta
                  {...data?.link}
                  className={css.productLink}
                  onClick={() => {
                    removeCurrent?.()
                  }}>
                  {t(PRODUCT.SEE_DETAILS)}
                </InlineCta>
              </div>
            </>
          )}
        </PopIn>
      </m.div>
    </AnimatePresence>
  )
}

function Skeleton() {
  return (
    <>
      <div className={css.imageWrapper}>
        <div className={css.loader} />
      </div>
      <div className={css.content}>
        <div className={css.title}>
          <div className={css.loader} />
        </div>
        <div className={css.cta}>
          <div className={css.loader} />
        </div>
        <div className={css.cta}>
          <div className={css.loader} />
        </div>
      </div>
    </>
  )
}

function QuickbuyPopin(props: ComponentProps<typeof InnerQuickbuyPopin>) {
  return (
    <ShopifyProductProvider
      id={props?.shopifyId}
      type={props?.type}
      includeMetafields>
      <InnerQuickbuyPopin {...props} />
    </ShopifyProductProvider>
  )
}

export default QuickbuyPopin
