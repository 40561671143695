import classnames from 'classnames/bind'
import { AnimatePresence, m } from 'framer-motion'
import { useEffect, useMemo } from 'react'
import { useTranslate } from 'react-polyglot'
import { useMeasure } from 'react-use'
import { GlobalBreakpoints } from '~/@types/breakpoints'
import { CardProduct } from '~/@types/card'
import { GlobalThemeColors } from '~/@types/colors'
import { Variant } from '~/@types/product'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'
import { TRACKING_BRAND, TRACKING_EVENTS } from '~/lib/constants'

import { useIsHover } from '@unlikelystudio/react-hooks'
import Slider, { useSliderState } from '@unlikelystudio/react-slider'

import Ratio from '~/components/Abstracts/Ratio'
import ShopifyRichText from '~/components/Abstracts/ShopifyRichText'
import VideoComponentForwarded from '~/components/Abstracts/Video'
import WrapperWithLink from '~/components/Abstracts/WrapperWithLink'
import { PRODUCT_TYPE_TRACKING } from '~/components/Panels/CartPanel/CartContent/types'
import { SliderNavigation } from '~/components/Slices/ProductsSlider'
import ColorsProduct, {
  ColorsProductProps,
} from '~/components/UI/ColorsProduct'
import { DiscountBadge } from '~/components/UI/DiscountBadge'
import Image from '~/components/UI/Image'
import Intensity, { IntensityProps } from '~/components/UI/Intensity'
import QuickBuyCTA from '~/components/UI/QuickBuyCTA'
import VariantsLabels from '~/components/UI/VariantsLabels'

import { useStyle } from '~/providers/StyleProvider'
import { useTracker } from '~/providers/TrackerProvider'

import {
  serializeTrackingProduct,
  TrackingProductData,
} from '~/hooks/tracking/useGTMTracking'
import {
  getDiscountPercentOfProduct,
  getDiscountValueOfProduct,
  getGTMTypeFromPrismicType,
  getNonDiscountedPriceProduct,
} from '~/hooks/tracking/utils'
import useBreakpoint from '~/hooks/useBreakpoint'
import useCurrency from '~/hooks/useCurrency'
import useShop from '~/hooks/useShop'

import { getCurrentColor } from '~/utils/get-current-color'
import {
  getIsProductFashion,
  getIsProductFragrance,
  getIsProductSet,
} from '~/utils/get-shopify-product-type'

import { PRODUCT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ProductCardProps extends CardProduct {
  intensity?: IntensityProps['intensity']
  colors?: ColorsProductProps['colors']
  trackingGTMData?: TrackingProductData
  productType?: string
  cardWidthClassName?: string
  hideVariants?: boolean
  priority?: boolean
  theme?: 'dark' | 'light'
  loading?: boolean
  index?: number
  quickbuyVariants?: Variant[]
  textsHeight?: (heightValue: number) => void
}

const IMAGE_SIZES = {
  DEFAULT: [{ ratio: 24 / 24 }],
}

function ProductCard({
  className,
  theme = 'dark',
  ...props
}: ProductCardProps) {
  const { tracker } = useTracker()
  const isMobile = useBreakpoint(GlobalBreakpoints.MD)
  const currency = useCurrency()
  const t = useTranslate()
  const [isHover, callbacks] = useIsHover()

  const [imagesRef, { height: imagesHeight }] = useMeasure()

  useEffect(() => {
    // need to match the image height
    props?.textsHeight?.(imagesHeight)
  }, [imagesHeight])

  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Title11_12Haffer,
    color: theme === 'dark' ? GlobalThemeColors.Black : GlobalThemeColors.White,
  })

  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Title11_12Haffer,
    color: GlobalThemeColors.DoveGray,
  })

  const priceStyle = useStyle({
    textPreset: GlobalTextPreset.Title11_12Haffer,
    color: theme === 'dark' ? GlobalThemeColors.Black : GlobalThemeColors.White,
  })

  const pinStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    color: theme === 'dark' ? GlobalThemeColors.Black : GlobalThemeColors.White,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const shop = useShop(props?.type)

  const handleClick = () => {
    tracker.emit(TRACKING_EVENTS.PRODUCT_CLICK, {
      eventLabel: props?.name,
      ecommerce: {
        click: {
          actionField: {
            list: props?.trackingGTMData?.list,
          },
          currencyCode: currency,
          products: [
            serializeTrackingProduct({
              item_id: props?.defaultVariant?.sku ?? null,
              id: props?.defaultVariant?.barcode?.toString() ?? null,
              name: props?.name,
              price: props?.defaultVariant?.priceAmount?.toString() ?? null,
              brand: TRACKING_BRAND,
              // re-enable it later
              // category: productType ?? null,
              category: getGTMTypeFromPrismicType(props?.type),
              type: getGTMTypeFromPrismicType(props?.type),
              color: getCurrentColor(props?.colors)?.name,
              discountPercentage: getDiscountPercentOfProduct(
                props?.defaultVariant,
              ),
              discount: getDiscountValueOfProduct(props?.defaultVariant),
              catalogPrice: getNonDiscountedPriceProduct(props?.defaultVariant),
              productType: getIsProductSet(props?.type)
                ? PRODUCT_TYPE_TRACKING.BUNDLE
                : PRODUCT_TYPE_TRACKING.PRODUCT,
              ...props?.trackingGTMData,
            }),
          ],
        },
      },
    })
  }

  const images = [props?.image, props?.imageHover]?.filter(Boolean)

  const [
    { slideIndex, maxSlideIndex, enableDrag, nextSlide, prevSlide },
    setSliderState,
  ] = useSliderState()

  const { isPrevVisible, isNextVisible } = useMemo(
    () => ({
      isPrevVisible: enableDrag && slideIndex !== 0,
      isNextVisible: enableDrag && slideIndex < maxSlideIndex,
    }),
    [enableDrag, maxSlideIndex, slideIndex],
  )

  const defaultCards = (
    <>
      {props?.image?.video && (
        <Ratio ratio={props?.ratio ?? css.ratio}>
          <VideoComponentForwarded
            {...props?.image?.video}
            autoPlay
            muted
            loop
            className={cx(css.video, css.cardVideo)}
            playsInline
            loading={props?.loading ? 'eager' : 'lazy'}
          />
        </Ratio>
      )}
      {props?.image?.image && (
        <Image
          draggable="false"
          layout="fill"
          objectFit="cover"
          className={cx(css.image)}
          ratio={props?.ratio ?? css.ratio}
          sizesFromBreakpoints={
            props?.sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT
          }
          asPlaceholder
          priority={props?.priority}
          {...props?.image?.image}
        />
      )}
      {props?.imageHover && (
        <AnimatePresence>
          {isHover && (
            <m.div
              className={cx(css.imageHover)}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.25 },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.25 },
              }}>
              {props?.imageHover.image && (
                <Image
                  draggable="false"
                  layout="fill"
                  objectFit="cover"
                  ratio={props?.ratio ?? css.ratio}
                  sizesFromBreakpoints={
                    props?.sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT
                  }
                  {...props?.imageHover.image}
                />
              )}
              {props?.imageHover.video && (
                <VideoComponentForwarded
                  {...props?.imageHover.video}
                  className={css.cardVideo}
                  autoPlay
                  muted
                  loop
                  playsInline
                />
              )}
            </m.div>
          )}
        </AnimatePresence>
      )}
    </>
  )

  const sliderLayout = (
    <>
      <div className={css.hideOnSmallScreen}>{defaultCards}</div>
      <div className={css.hideOnLargeScreen}>
        {images?.length > 1 ? (
          <>
            <Slider
              className={css.slider}
              snap
              infinite={true}
              maxSlideIndexChange={1}
              setSliderState={setSliderState}>
              {images?.map((image, index) => {
                return image?.image ? (
                  <Image
                    key={`image_${index}`}
                    draggable="false"
                    layout="fill"
                    objectFit="cover"
                    className={cx(css.image, props?.cardWidthClassName)}
                    ratio={props?.ratio ?? css.ratio}
                    sizesFromBreakpoints={
                      props?.sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT
                    }
                    asPlaceholder
                    {...image?.image}
                  />
                ) : (
                  <Ratio
                    className={props?.cardWidthClassName}
                    ratio={props?.ratio ?? css.ratio}>
                    <VideoComponentForwarded
                      key={`video_${index}`}
                      {...image?.video}
                      autoPlay
                      muted
                      loop
                      playsInline
                      className={cx(css.video, css.cardVideo)}
                    />
                  </Ratio>
                )
              })}
            </Slider>
            {images?.length > 1 && (
              <SliderNavigation
                className={cx(css.sliderNavigation)}
                iconClassName={css.sliderIcon}
                isPrevVisible={isPrevVisible}
                isNextVisible={isNextVisible}
                onPrev={prevSlide}
                onNext={nextSlide}
                hasSeparator={false}
              />
            )}
          </>
        ) : props?.image?.image ? (
          <Image
            draggable="false"
            layout="fill"
            objectFit="cover"
            className={cx(css.image)}
            ratio={props?.ratio ?? css.ratio}
            sizesFromBreakpoints={
              props?.sizesFromBreakpoints ?? IMAGE_SIZES.DEFAULT
            }
            asPlaceholder
            {...props?.image?.image}
          />
        ) : (
          <Ratio ratio={props?.ratio ?? css.ratio}>
            <VideoComponentForwarded
              {...props?.image?.video}
              autoPlay
              muted
              loop
              playsInline
              className={cx(css.video, css.cardVideo)}
            />
          </Ratio>
        )}
      </div>
    </>
  )

  return (
    <div
      className={cx(css.ProductCard, className, props?.cardWidthClassName)}
      {...callbacks}>
      <WrapperWithLink
        {...props?.link}
        onClick={handleClick}
        className={css.cardWrapper}>
        <div className={css.images} ref={imagesRef}>
          {props?.cardWidthClassName ? sliderLayout : defaultCards}

          <div className={cx(css.cardInfos)}>
            {!props?.colors &&
              props?.intensity &&
              getIsProductFragrance(props?.type) && (
                <Intensity
                  visible={isHover || isMobile}
                  className={cx(css.cardInfosItem, css.intensity)}
                  intensity={props?.intensity}
                />
              )}

            {
              // Hide if non ecom
              shop &&
                !props?.hideVariants &&
                // Hide if only one variant (eg: TU)
                props?.variants &&
                props?.variants?.length > 1 &&
                getIsProductFashion(props?.type) && (
                  <VariantsLabels
                    visible={isHover || isMobile}
                    className={cx(css.cardInfosItem, css.variantsLabels)}
                    variants={props?.variants}
                  />
                )
            }
          </div>
        </div>
        <div className={css.texts}>
          <div className={css.topText}>
            {props?.name && (
              <h3 className={cx(css.name, nameStyle)}>{props?.name}</h3>
            )}
            <ShopifyRichText
              className={cx(css.label, labelStyle)}
              render={props?.label}
            />
          </div>

          {
            // Hide if non ecom
            shop && (
              <div className={cx(css.prices)}>
                {props?.compareAtPrice && (
                  <p className={cx(css.compareAtPrice, priceStyle)}>
                    {props?.compareAtPrice}
                  </p>
                )}
                {props?.price && (
                  <div className={cx(css.price, priceStyle)}>
                    {props?.variants?.length > 1 &&
                    !getIsProductFashion(props?.type)
                      ? `${t(PRODUCT.FROM)} `
                      : ''}
                    {props?.price}
                  </div>
                )}
              </div>
            )
          }
        </div>
      </WrapperWithLink>
      {!props?.intensity && props?.colors && (
        <ColorsProduct
          visible={isHover || isMobile}
          className={cx(css.cardInfosItem, css.colorsProduct)}
          colors={props?.colors}
        />
      )}
      <ShopifyRichText className={cx(css.pin, pinStyle)} render={props?.pin} />
      {props?.computedDiscount && (
        <DiscountBadge
          className={css.discountBadge}
          value={props?.computedDiscount}
        />
      )}
      {shop && props?.quickbuyVariants && (
        <QuickBuyCTA
          className={css.quickbuy}
          type={props?.type}
          colorVariants={props?.colors}
          {...props}
        />
      )}
    </div>
  )
}

ProductCard.defaultProps = {}

export default ProductCard
