import { useQuery } from '@tanstack/react-query'
import Error from 'next/error'

import { UnlikelyProduct } from '@unlikelystudio/commerce-connector'

import { ProductCardProps } from '~/components/Slices/ProductCard'

import useLocale from '~/hooks/useLocale'

import { serializeCardProductContent } from '~/data/serialize-card-product'

export function useGetProductCard({
  sfProduct,
  initialData,
}: {
  sfProduct: UnlikelyProduct
  initialData: ProductCardProps
}) {
  const locale = useLocale()
  return useQuery<
    ProductCardProps,
    Error,
    ProductCardProps,
    ['get-product-card', { sfProductId: UnlikelyProduct['id'] }]
  >({
    queryKey: ['get-product-card', { sfProductId: sfProduct?.id }],
    queryFn: async () => {
      return await serializeCardProductContent(sfProduct, locale)
    },
    initialData,
    enabled: initialData?.shopifyId !== sfProduct?.id,
    keepPreviousData: true,
  })
}
