import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react'

import { GID, UnlikelyProduct } from '@unlikelystudio/commerce-connector'
import { Product, useGetProduct } from '@unlikelystudio/react-ecommerce-hooks'

import { ProductHeaderProps } from '~/components/UI/ProductHeader/types'

import useLocale from '~/hooks/useLocale'

import { getShopifyProductMetafieldsList } from '~/data/product-page-data/serialize-product-metafields'
import {
  getDefaultVariant,
  serializeVariants,
} from '~/data/product-page-data/serialize-variants'
import serializeImage from '~/data/serialize-image'

export const ShopifyProductContext = createContext<ShopifyProductContextValue>({
  uid: '',
})

interface ShopifyProductProviderProps {
  children: ReactNode | ReactNode[]
  id: GID
  type: string
  uid?: string
  includeMetafields?: boolean
}

export interface ShopifyProductContextValue extends ProductHeaderProps {
  isLoading?: boolean
  isSuccess?: boolean
  productData?: UnlikelyProduct
  setProductId?: Dispatch<SetStateAction<GID>>
}

/**
 * It takes a Shopify Product and a product ID, and returns a serialized version of the Product
 * @param {Product} data - Product - this is the data that is returned from the Shopify API.
 * @param {string} productID - The ID of the product that the variant belongs to.
 * @returns An array of variants
 */
function serializeIntermediateShopifyProduct(data: Product, productID: string) {
  return {
    variants: data?.variants?.map?.((currentVariant) => {
      return {
        id: currentVariant?.id ?? null,
        productId: `${productID}`,
        barcode: currentVariant?.barcode ?? null,
        sku: currentVariant?.sku ?? null,
        title: currentVariant?.title ?? null,
        availableForSale: currentVariant?.availableForSale ?? false,
        quantityAvailable: currentVariant?.quantityAvailable ?? 0,
        price: currentVariant?.price ?? null,
        compareAtPrice: currentVariant?.compareAtPrice ?? null,
        image: serializeImage(currentVariant?.image) ?? null,
      }
    }),
  }
}

export default function ShopifyProductProvider({
  id,
  children,
  type,
  uid,
  includeMetafields,
}: ShopifyProductProviderProps) {
  const [productId, setProductId] = useState<GID>(id)

  const { data, isLoading, isSuccess } = useGetProduct({
    id: productId,
    variantsFirst: 100,
    includePrices: true,
    includeQuantityAvailable: true,
    ...(includeMetafields && {
      metafieldKeys: getShopifyProductMetafieldsList('productCard'),
    }),
  })

  const locale = useLocale()

  const intermediateProduct = serializeIntermediateShopifyProduct(
    data as Product,
    `${productId}`,
  )

  const variants =
    serializeVariants(intermediateProduct?.variants, locale, { uid }) ?? null

  const defaultVariant = getDefaultVariant(variants, type, true) ?? null

  const shopifyProduct = {
    uid,
    variants,
    defaultVariant,
  }

  const processedValue: ShopifyProductContextValue = useMemo(
    () => ({
      ...shopifyProduct,
      isLoading,
      isSuccess,
      productData: data,
      setProductId,
    }),
    [isLoading, isSuccess, JSON.stringify(shopifyProduct)],
  )

  return (
    <ShopifyProductContext.Provider value={processedValue}>
      {children}
    </ShopifyProductContext.Provider>
  )
}

export function useShopifyProductContext() {
  return useContext(ShopifyProductContext)
}
