import classnames from 'classnames/bind'
import { useRouter } from 'next/router'

import BigButton from '~/components/UI/BigButton'
import { ProductVariantsProps } from '~/components/UI/ProductHeader/ProductVariants'

import css from './styles.module.scss'
import { useTransition } from 'react'

const cx = classnames.bind(css)

export type VariantsFragranceProps = Omit<ProductVariantsProps, 'type'>

function VariantsFragrance({
  className,
  variants,
  active,
}: VariantsFragranceProps) {
  const router = useRouter()
  const [isPending, startTransition] = useTransition()

  return (
    <div className={cx(css.VariantsFragrance, className)}>
      {variants?.map((variant, index) => {
        return (
          <BigButton
            className={cx(css.button)}
            isOutOfStock={variant?.availableForSale === false}
            key={`variant_${index}`}
            active={active?.id === variant?.id}
            onClick={() => {
              startTransition(() => {
                router.push(
                  {
                    pathname: router.pathname,
                    query: {
                      ...router?.query,
                      variant: variant.title,
                    },
                  },
                  undefined,
                  { shallow: true },
                )
              })
            }}>
            {variant.title}
          </BigButton>
        )
      })}
    </div>
  )
}

VariantsFragrance.defaultProps = {}

export default VariantsFragrance
