import { useMemo } from 'react'
import { useTranslate } from 'react-polyglot'
import type { Variant } from '~/@types/product'

import type { Option } from '~/components/Abstracts/Form/Select'

import { CART, PRODUCT } from '~/data/dictionary'

export default function useVariantsToOptions(variants: Variant[]): Option[] {
  const t = useTranslate()

  return useMemo(
    () =>
      variants?.map((variant) => ({
        value: variant?.title,
        label: `${t(PRODUCT.SIZE)} : ${variant?.title}${
          variant?.availableForSale ? '' : ` - ${t(CART.OUT_OF_STOCK)}`
        }`,
      })) ?? [],
    [variants],
  )
}
