import { useEffect } from 'react'
import { useInView } from 'react-intersection-observer'
import { TRACKING_BRAND, TRACKING_EVENTS } from '~/lib/constants'

import { ProductCardProps } from '~/components/Slices/ProductCard'

import { useTracker } from '~/providers/TrackerProvider'

import { serializeTrackingProduct } from '~/hooks/tracking/useGTMTracking'
import {
  getDiscountPercentOfProduct,
  getGTMTypeFromPrismicType,
  getNonDiscountedPriceProduct,
  getProductTypeTracking,
} from '~/hooks/tracking/utils'
import useCurrency from '~/hooks/useCurrency'

import chunk from '~/utils/chunk'
import { getCurrentColor } from '~/utils/get-current-color'

export default function useTrackingProductImpression(
  data: ProductCardProps[] | ProductCardProps,
) {
  const { ref, inView } = useInView({ triggerOnce: true })
  const { tracker } = useTracker()
  const currency = useCurrency()

  useEffect(() => {
    if (inView) {
      /* A promotion can handle only 5 products so with need to chunk it and to send multiple events depending on how much chunks existed */
      const chunkedImpressions = Array.isArray(data)
        ? chunk(data, 5)?.map((chuncked) =>
          Array(5)
            .fill(undefined)
            ?.map((val, index) => {
              const data = chuncked[index]
              if (!data) return undefined
              const {
                name,
                type,
                colors,
                trackingGTMData,
                defaultVariant,
                productType,
              } = data
              return serializeTrackingProduct({
                id: defaultVariant?.barcode?.toString() ?? null,
                position: trackingGTMData?.position,
                name: name ?? null,
                price: defaultVariant?.priceAmount?.toString() ?? null,
                brand: TRACKING_BRAND,
                // re-enable it later
                // category: productType ?? null,
                category: getGTMTypeFromPrismicType(type) ?? null,
                type: getGTMTypeFromPrismicType(type) ?? null,
                color: getCurrentColor(colors)?.name ?? null,
                list: trackingGTMData?.list ?? null,
                discountPercentage:
                  getDiscountPercentOfProduct(defaultVariant),
                catalogPrice: getNonDiscountedPriceProduct(defaultVariant),
                productType: getProductTypeTracking(type),
              })
            }),
        )
        : [
          [
            serializeTrackingProduct({
              item_id: data?.defaultVariant?.sku ?? null,
              id: data?.defaultVariant?.barcode?.toString() ?? null,
              name: data?.name ?? null,
              price: data?.defaultVariant?.priceAmount?.toString() ?? null,
              brand: TRACKING_BRAND,
              // re-enable it later
              // category: data?.productType ?? null,
              category: getGTMTypeFromPrismicType(data?.type) ?? null,
              type: getGTMTypeFromPrismicType(data?.type) ?? null,
              color: getCurrentColor(data?.colors)?.name ?? null,
              position: data?.trackingGTMData?.position,
              list: data?.trackingGTMData?.list ?? null,
              discountPercentage: getDiscountPercentOfProduct(
                data?.defaultVariant,
              ),
              catalogPrice: getNonDiscountedPriceProduct(
                data?.defaultVariant,
              ),
              productType: getProductTypeTracking(data?.type),
            }),
          ],
        ]

      chunkedImpressions?.forEach((impressions) =>
        tracker.emit(TRACKING_EVENTS.PRODUCT_IMPRESSION, {
          ecommerce: {
            currencyCode: currency ?? 'none',
            impressions,
          },
        }),
      )
    }
  }, [inView])

  return { ref, inView }
}
