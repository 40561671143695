import classnames from 'classnames/bind'

import AbstractPopin, {
  PopInProps as AbstractPopInProps,
} from '~/components/Abstracts/PopIn'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface PopinProps extends AbstractPopInProps {
  className?: string
}

function Popin({ className, children, closeClassName, ...rest }: PopinProps) {
  return (
    <AbstractPopin
      className={cx(css.PopIn, className)}
      {...rest}
      closeClassName={cx(closeClassName, css.PopInCloseIcon)}>
      {children}
    </AbstractPopin>
  )
}

Popin.defaultProps = {}

export default Popin
