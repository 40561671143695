import classnames from 'classnames/bind'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import { useStyle } from '~/providers/StyleProvider'

import { PRODUCT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface IntensityProps {
  className?: string
  label?: string
  intensity: 1 | 2 | 3 | 4 | 5
  visible?: boolean
}

export const MAX_INTENSITY = 5
function Intensity({ className, label, intensity, visible }: IntensityProps) {
  const t = useTranslate()
  const labelStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    color: GlobalThemeColors.Black,
  })

  return (
    <div className={cx(css.Intensity, className, { visible })}>
      <span className={cx(css.label, labelStyle)}>
        {label || t(PRODUCT.INTENSITY)}
      </span>
      {Array.from(Array(MAX_INTENSITY).keys()).map((_, i) => (
        <span
          key={`intensity_${i}`}
          className={cx(css.dot, { active: i < intensity })}></span>
      ))}
    </div>
  )
}

Intensity.defaultProps = {}

export default Intensity
