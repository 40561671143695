import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import { useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import MockSelect from '~/components/Abstracts/Form/Select/mock'
import { ArrowSelectIcon } from '~/components/Icons'
import { ProductVariantsProps } from '~/components/UI/ProductHeader/ProductVariants'
import useVariantsToOptions from '~/components/UI/VariantsFashion/hooks/useVariantsToOptions'

import { useStyle } from '~/providers/StyleProvider'

import { CART, PRODUCT } from '~/data/dictionary'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type VariantsFashionProps = Omit<ProductVariantsProps, 'type'>

function VariantsFashion({
  className,
  active,
  variants,
}: VariantsFashionProps) {
  const selectRef = useRef<HTMLSelectElement>()
  const router = useRouter()
  const t = useTranslate()

  const selectStyle = useStyle({
    textPreset: GlobalTextPreset.Label11Haffer,
    color: GlobalThemeColors.Black,
    textStyling: GlobalTextStyling.UpperCase,
  })

  const options = useVariantsToOptions(variants)

  const onChange = (e) => {
    router.push(
      {
        pathname: router.pathname,
        query: {
          ...router?.query,
          variant: e.target.value,
        },
      },
      undefined,
      { shallow: true },
    )
  }

  return variants?.length > 0 ? (
    <div className={cx(css.VariantsFashion, className)}>
      <div className={cx(css.input, selectStyle)}>
        <span>
          {t(PRODUCT.SIZE)} : {active?.title}
          {active?.availableForSale ? '' : ` - ${t(CART.OUT_OF_STOCK)}`}
        </span>
        <ArrowSelectIcon fill="#000000" className={cx(css.icon)} />
      </div>
      <MockSelect
        forwardRef={selectRef}
        className={cx(css.select)}
        placeholder={t(PRODUCT.SELECT_YOUR_SIZE)}
        currentValue={active?.title}
        options={options}
        onChange={onChange}
      />
    </div>
  ) : null
}

VariantsFashion.defaultProps = {}

export default VariantsFashion
