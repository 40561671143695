import classnames from 'classnames/bind'
import { useState } from 'react'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'
import { GlobalTextStyling } from '~/@types/text-styling'

import { useIsHover } from '@unlikelystudio/react-hooks'

import { ColorProduct } from '~/components/UI/ColorsProduct'
import Link from '~/components/UI/Link'

import { useStyle } from '~/providers/StyleProvider'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface ColorsProductHeaderProps {
  className?: string
  items?: ColorProduct[]
}

function ColorsProductLink({
  current,
  link,
  name,
  hasBorder,
  color,
  index,
  currentIndex,
  onMouseEnter,
}: ColorProduct) {
  const nameStyle = useStyle({
    textPreset: GlobalTextPreset.Label11_12Haffer,
    textStyling: GlobalTextStyling.UpperCase,
    color: GlobalThemeColors.DoveGray,
  })
  const [, mouseEvents] = useIsHover({
    onMouseEnter: () => onMouseEnter?.(index),
  })

  return (
    <Link
      className={cx(css.item, {
        current: currentIndex === null ? current : currentIndex === index,
      })}
      aria-label={name}
      {...link}
      {...mouseEvents}>
      <>
        <div
          className={cx(css.center, { hasBorder })}
          style={{ backgroundColor: color }}
        />
        <div className={cx(css.name, nameStyle)}>{name}</div>
      </>
    </Link>
  )
}
function ColorsProductHeader({ className, items }: ColorsProductHeaderProps) {
  const [indexHovered, setIndexHovered] = useState(null)
  const [, mouseEvents] = useIsHover({
    onMouseLeave: () => setIndexHovered(null),
  })

  return (
    <div className={cx(css.ColorsProductHeader, className)} {...mouseEvents}>
      {items?.map((item, index) => {
        const { currentIndex, ...props } = item

        return (
          <ColorsProductLink
            key={`color_item_${index}`}
            onMouseEnter={setIndexHovered}
            index={index}
            currentIndex={indexHovered ?? currentIndex ?? null}
            {...props}
          />
        )
      })}
    </div>
  )
}

ColorsProductHeader.defaultProps = {}

export default ColorsProductHeader
