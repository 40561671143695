import classnames from 'classnames/bind'
import { GlobalThemeColors } from '~/@types/colors'
import { GlobalTextPreset } from '~/@types/text-preset'

import Image from '~/components/UI/Image'

import { useGlobalData } from '~/providers/GlobalDataProvider'
import { useStyle } from '~/providers/StyleProvider'

import useKlarna from '~/hooks/useKlarna'
import useShop from '~/hooks/useShop'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export interface KlarnaLabelProps {
  className?: string
  type?: string
}

function KlarnaLabel({ className, type }: KlarnaLabelProps) {
  const shop = useShop(type)
  const hasKlarna = useKlarna()
  const { global } = useGlobalData()

  const klarnaLabelStyle = useStyle({
    textPreset: GlobalTextPreset.Text11Haffer,
    color: GlobalThemeColors.MineShaft,
  })

  return shop && hasKlarna && global?.productPayments?.klarnaLabel ? (
    <div className={cx(css.KlarnaLabel, className)}>
      <p className={cx(css.klarna, klarnaLabelStyle)}>
        {global?.productPayments?.klarnaLabel}
      </p>
      {global?.productPayments?.klarnaLogo && (
        <Image className={css.image} {...global?.productPayments?.klarnaLogo} />
      )}
    </div>
  ) : null
}

KlarnaLabel.defaultProps = {}

export default KlarnaLabel
