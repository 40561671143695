import classnames from 'classnames/bind'
import { useEffect } from 'react'
import { useTranslate } from 'react-polyglot'
import { GlobalThemeColors } from '~/@types/colors'
import { PropsWithClassName } from '~/@types/generic'

import QuickbuyPopin, {
  QuickbuyPopinProps,
} from '~/components/Popins/QuickbuyPopin'
import SquaredCta from '~/components/UI/SquaredCta'

import { usePopIn } from '~/providers/PopInProvider'

import useBreakpoint from '~/hooks/useBreakpoint'

import css from './styles.module.scss'

const cx = classnames.bind(css)

export type QuickBuyCTAProps = PropsWithClassName<QuickbuyPopinProps>

function QuickBuyCTA({ className, ...rest }: QuickBuyCTAProps) {
  const t = useTranslate()
  const { add, removeCurrent } = usePopIn()
  const isMobile = useBreakpoint('md')

  const showQuickbuyPopin = () => !isMobile && add(<QuickbuyPopin {...rest} />)

  useEffect(() => {
    removeCurrent()
  }, [isMobile])

  return (
    <SquaredCta
      className={cx(css.QuickBuyCTA, className)}
      theme={GlobalThemeColors.White}
      onClick={showQuickbuyPopin}>
      {t('cta.quickbuy')}
    </SquaredCta>
  )
}

export default QuickBuyCTA
