import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { Variant } from '~/@types/product'

export default function useVariant(
  variants: Variant[],
  defaultVariant: Variant,
) {
  const router = useRouter()
  const queryKey = 'variant'

  // First load issue with next query router, we checked the asPath to be sure the variant was in the url
  const regex = new RegExp(`[?&]${queryKey}=([^&]+)`)
  const matching = router.asPath.match(regex)
  let queryValue = router.query[queryKey] || matching ? matching?.[1] : null

  // We have to trap the queryValue here in case its value is null :
  // - decodeURIComponent(null) will return a "null" string
  // - If so, it will make the following ternary (currentVariant) always getting into the true condition
  if (queryValue) {
    // Decoding the URIComponent to avoid getting raw entities
    queryValue = decodeURIComponent(queryValue)
  }

  const currentVariant = queryValue
    ? variants?.find((variant) => {
        return (
          variant?.title ===
          /* It's replacing the `+` and `%20` with a space. */
          queryValue?.toString()?.replace(/\+/g, ' ')
        )
      })
    : null

  const [selectedVariant, setSelectedVariant] = useState<Variant | null>(
    currentVariant || defaultVariant,
  )
  const [isAvailableForSale, setIsAvailableForSale] = useState<boolean>(true)

  useEffect(() => {
    const variant = currentVariant || defaultVariant

    if (variant && variant !== selectedVariant) setSelectedVariant(variant)
  }, [currentVariant, defaultVariant])

  useEffect(() => {
    const currentVariant =
      (selectedVariant
        ? selectedVariant
        : variants
        ? variants?.[0]
        : defaultVariant) ?? null

    if (currentVariant) {
      setIsAvailableForSale(currentVariant?.availableForSale)
    }
  }, [selectedVariant, variants, defaultVariant])

  const variantIndex = variants?.findIndex((item) => {
    return item?.id === selectedVariant?.id
  })

  return {
    selectedVariant,
    setSelectedVariant,
    variantIndex,
    isAvailableForSale,
  }
}
